import React from 'react';
import './SelectBox.css';
import IMAGES from '../images';

export default function SelectBox({ isSelected, onSelection, option }) {
  const imageUrl = IMAGES[option.image] || 'https://t4.ftcdn.net/jpg/02/07/87/79/360_F_207877921_BtG6ZKAVvtLyc5GWpBNEIlIxsffTtWkv.jpg';

  return (
    <div className='box-container'>
      <div className={`select-box ${isSelected ? 'selected' : ''}`} onClick={onSelection} style={{borderRadius: 6, overflow: "hidden"}}>
        {option.image ? <img
          src={imageUrl}
          alt={option.name}
          style={{
            height: "140px",
            width: "140px",
            objectFit: "cover",
            
          }}
        /> : <div style={{backgroundColor: "white", width: "100%", height: "100%", display: "flex", justifyContent: "center", alignItems: "center", color: "#009DE0", border: "1px solid #009DE0", borderRadius: 5, fontWeight: "bold"}}>
          {option.name}
          </div>}
      </div>
      <div>
        {option.image && option.name}
      </div>
    </div>
  );
}
