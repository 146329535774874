import React, { useState } from 'react';
import Heading from '../components/Heading';
import Description from '../components/Description';
import SelectBox from '../components/SelectBox';
import './Standard.css';
import Input from '../components/Input';
import { forwardRef, useImperativeHandle } from 'react';

  const Standard = forwardRef(({ value, heading, description, options, selectedOption, updateSelectedOption, selectionRequired}, ref) => {

    const validateStandardPage = () => {
      return new Promise((resolve, reject) => {
        if (selectionRequired && !selectedOption) {
          const errorMessage = `Auswahl erforderlich.`;
          reject (errorMessage);
          return
        }
        resolve (true)
      });
    };
  
    useImperativeHandle(ref, () => ({
      validate: validateStandardPage
    }));

    function updateSelection(value){
      if(value === selectedOption){
        if(!selectionRequired){
          updateSelectedOption(null);
        } else {
          updateSelectedOption(value);
        }
      } else {
        updateSelectedOption(value);
      }
    }

  return (
    <div className='standard-container'>
      <div className='standard-heading'>
        <Heading text={heading} />
      </div>
      
      <div className='standard-description'>
        <Description text={description} />
      </div>

      <div className='standard-options'>
        {options.map((option, index) => (
          <SelectBox
            key={index}
            isSelected={selectedOption === option.value}
            onSelection={() => updateSelection(option.value)}
            option={option}
          />
        ))}
      </div>
    </div>
  );
}
);
export default Standard;
