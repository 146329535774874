import React, { useState } from 'react';
import { Input, Textarea } from '@mantine/core';
import { TextInput } from '@mantine/core';
import { FileInput, rem } from '@mantine/core';
import { createClient } from '@supabase/supabase-js';
import { v4 as uuidv4 } from 'uuid'; // Importiere die uuid-Bibliothek

async function sendMail() {
  console.log('sendMail');

  const supabaseUrl = 'https://lhxbenrqfrfdrglonkpw.supabase.co';
  const supabaseKey = 'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpc3MiOiJzdXBhYmFzZSIsInJlZiI6ImxoeGJlbnJxZnJmZHJnbG9ua3B3Iiwicm9sZSI6ImFub24iLCJpYXQiOjE3MTAyNDU2ODAsImV4cCI6MjAyNTgyMTY4MH0.-vvTZgcvf4tEgfpcxfd3lvsDI8WEobNWhr-IeiTGZ_g';
  const supabase = createClient(supabaseUrl, supabaseKey);

  try {
    // const { data, error } = await supabase
    //   .from('betonzaun-fabrik-configurator')
    //   .rpc('send-mail', { name: "Nils" });
    const { data, error } = await supabase.functions.invoke('send-mail', {
      body: JSON.stringify({})
    })

    if (error) {
      console.error('Fehler beim Senden der E-Mail:', error);
      return;
    }

    console.log('E-Mail erfolgreich gesendet:', data);
  } catch (e) {
    console.error('Unerwarteter Fehler:', e);
  }
}

export default function InputC({ type, placeholder, value, onChange, optionValue, lable, description}) {
  const [inputValue, setInputValue] = useState(value ? value : "");
  const [fileName, setFileName] = useState(null);
  const [fileUrl, setFileUrl] = useState(null);

  const supabaseUrl = 'https://lhxbenrqfrfdrglonkpw.supabase.co';
  const supabaseKey = 'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpc3MiOiJzdXBhYmFzZSIsInJlZiI6ImxoeGJlbnJxZnJmZHJnbG9ua3B3Iiwicm9sZSI6ImFub24iLCJpYXQiOjE3MTAyNDU2ODAsImV4cCI6MjAyNTgyMTY4MH0.-vvTZgcvf4tEgfpcxfd3lvsDI8WEobNWhr-IeiTGZ_g';
  const supabase = createClient(supabaseUrl, supabaseKey);

  const handleChange = (event) => {
    setInputValue(event.target.value);
    onChange(event.target.value, optionValue);
  };

  const handleFileChange = async (file) => {
    console.log(file.name);
    setFileName(file.name);
    const randomFileName = uuidv4();

    const { data, error } = await supabase.storage
      .from('grundrisse') 
      .upload(`/${randomFileName}`, file);

    if (error) {
      console.error('Fehler beim Hochladen der Datei:', error.message);
    } else {
      const fullPath = data.fullPath
      const id = data.id
      const path = data.path

      {
      const { data, error } = await supabase
        .storage
        .from('grundrisse')
        .createSignedUrl(`/${path}`, 60)

        setFileUrl(data.signedUrl);
        onChange(data.signedUrl, optionValue);
        // sendMail();
      }
    }

    
  };

  if (type === 'text' || type === 'number'){
    return (
      <Input.Wrapper label={lable} description={description} error="" style={{width: '86%'}}>
        <Input 
          placeholder={placeholder} 
          value={inputValue} 
          onChange={handleChange} 
          style={{width: '100%'}}
          type={type}
          inputMode={type === 'number' ? 'numeric' : 'text'}
      />
      </Input.Wrapper>
    );
  }
  else if(type === "file"){
    return(
      <>
        <FileInput
          label={lable}
          description={description}
          placeholder={placeholder} 
          leftSectionPointerEvents="none"
          value={fileName} 
          // onChange={setFileValue}
          onChange={handleFileChange}
        />
      </>
    )
  }
  else if(type === "longText"){
    return(
      <Input.Wrapper label={lable} description={description} error="" style={{width: '86%'}}>
        <Textarea
          placeholder={placeholder}
          value={inputValue}
          onChange={handleChange}
          style={{width: '100%'}}
        />
    </Input.Wrapper>
     
    )
  }


}