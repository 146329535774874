import React, { useState } from 'react';
import Heading from '../components/Heading';
import Description from '../components/Description';
import SelectBox from '../components/SelectBox';
import './Standard.css';
import Input from '../components/Input';

export default function Success({ value, heading, description, options, selectedOption, updateSelectedOption}) {

  return (
    <div className='standard-container'>
      <div className='standard-heading'>
        <Heading text={heading} />
      </div>
      
      <div className='standard-description'>
        <Description text={description} />
      </div>


      <svg width="300" height="407" viewBox="0 0 552 407" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M193.031 407L0 214.077L48.2577 165.846L193.031 310.538L503.742 0L552 48.2308L193.031 407Z" fill="#009DE0" fillOpacity="0.36"/>
    </svg>

    </div>
  );
}
