import React, { useState, useEffect } from 'react';
import Heading from '../components/Heading';
import Description from '../components/Description';
import SelectBox from '../components/SelectBox';
import './Categories.css';
import Input from '../components/Input';
import { forwardRef, useImperativeHandle } from 'react';


const Categories = forwardRef(({ value, heading, description, options, selectedOption, updateSelectedOption, selectionRequired, goToNextPage}, ref) => {
  console.log("updateSelectedOption 11", updateSelectedOption)
  console.log("goToNextPage 11", goToNextPage)
  const validateStandardPage = () => {
    return new Promise((resolve, reject) => {
      if (selectionRequired && !selectedOption) {
        const errorMessage = `Auswahl erforderlich.`;
        reject (errorMessage);
        return
      }
      resolve (true)
    });
  };

  useImperativeHandle(ref, () => ({
    validate: validateStandardPage
  }));

  const [categories, setCategories] = useState([]);

  useEffect(() => {
    let newCategories = [];
    options.forEach((option) => {
      if (option.kategorie) {
        const category = option.kategorie;
        if (!newCategories.includes(category)) {
            newCategories.push(category);
        }
      }
    });
    setCategories(newCategories);
  }, [options]);

  function updateSelection(value){
    console.log(value, selectedOption)
    if(value === selectedOption){
      if(!selectionRequired){
        updateSelectedOption(null);
      } else {
        updateSelectedOption(value);
      }
    } else {
      updateSelectedOption(value);
    }
    //goToNextPage()
  }

  return (
    <div className='categories-container'>
      <div className='categories-heading'>
        <Heading text={heading} />
      </div>

      {/* <div className='categories-description'>
        <Description text={description} />
      </div> */}

      <div className='categories-options'>
        {categories.map((category, categoryIndex) => (
          <div key={categoryIndex}>
            <h3>{category}</h3>
            <div className='categories-options2'>
                {options
                .filter((option) => option.kategorie === category)
                .map((filteredOption, index) => (
                  <>
                    <SelectBox
                    key={index}
                    isSelected={selectedOption?.value === filteredOption.value}
                    onSelection={() => {updateSelection(filteredOption)}}
                    option={filteredOption}
                    />
                    </>
                ))}
              </div>
          </div>
        ))}
      </div>
    </div>
  );
}
);

export default Categories;