import React, { useState } from 'react';
import Heading from '../components/Heading';
import Description from '../components/Description';
import SelectBox from '../components/SelectBox';
import './Inputs.css';
import InputC from '../components/Input';
import { forwardRef, useImperativeHandle } from 'react';

const Inputs = forwardRef(({ value, heading, description, options, selectedOption, updateSelectedOption}, ref) => {

  const validateStandardPage = () => {
    return new Promise((resolve, reject) => {
      for (const option of options) {
        if (option.required && (selectedOption[option.value] === null || selectedOption[option.value] === '')) {
          const errorMessage = `${option.name} ist erforderlich.`;
          reject(errorMessage);
          return;
        }
      }
      resolve(true);
    });
  };

  useImperativeHandle(ref, () => ({
    validate: validateStandardPage
  }));

  const handleInputChange = (inputValue, optionValue) => {
    selectedOption[optionValue] = inputValue;
    updateSelectedOption(selectedOption)
  };

  return (
    <div className='inputs-container'>
      <div className='heading'>
        <Heading text={heading} />
      </div>
      
      <div className='inputs-description'>
        <Description text={description} />
      </div>

      <div className='inputs-options'>
        {options.map((option, index) => (
          <InputC
            key={index}
            lable={option.name}
            description={option.info}
            placeholder={option.placeholder}
            value={selectedOption[option.value]}
            optionValue={option.value}
            onChange={(inputValue, optionValue) => handleInputChange(inputValue, optionValue)}
            type={option.type ? option.type : 'text'}
          />
        ))}
      </div>
    </div>
  );
}
);
export default Inputs;
