const IMAGES = {
    //Klassik
    klassik_1: require('./assets/images/modell/klassik/klassik_1.jpg'),
    klassik_2: require('./assets/images/modell/klassik/klassik_2.jpg'),
    klassik_3: require('./assets/images/modell/klassik/klassik_3.jpg'),
    klassik_4: require('./assets/images/modell/klassik/klassik_4.jpg'),
    klassik_5: require('./assets/images/modell/klassik/klassik_5.jpg'),
    klassik_6: require('./assets/images/modell/klassik/klassik_6.jpg'),
    klassik_7: require('./assets/images/modell/klassik/klassik_7.jpg'),
    klassik_8: require('./assets/images/modell/klassik/klassik_8.jpg'),
    klassik_9: require('./assets/images/modell/klassik/klassik_9.jpg'),
    klassik_10: require('./assets/images/modell/klassik/klassik_10.jpg'),

    //premium
    premium_1: require('./assets/images/modell/premium/premium_1.jpg'),
    premium_2: require('./assets/images/modell/premium/premium_2.jpg'),
    premium_3: require('./assets/images/modell/premium/premium_3.jpg'),
    premium_4: require('./assets/images/modell/premium/premium_4.jpg'),
    premium_5: require('./assets/images/modell/premium/premium_5.jpg'),
    premium_6: require('./assets/images/modell/premium/premium_6.jpg'),
    premium_7: require('./assets/images/modell/premium/premium_7.jpg'),
    premium_8: require('./assets/images/modell/premium/premium_8.jpg'),
    premium_9: require('./assets/images/modell/premium/premium_9.jpg'),
    premium_10: require('./assets/images/modell/premium/premium_10.jpg'),

    //premiumPlus
    premium_plus_1: require('./assets/images/modell/premium_plus/premium_plus_1.jpg'),
    premium_plus_2: require('./assets/images/modell/premium_plus/premium_plus_2.jpg'),
    premium_plus_3: require('./assets/images/modell/premium_plus/premium_plus_3.jpg'),
    premium_plus_4: require('./assets/images/modell/premium_plus/premium_plus_4.jpg'),
    premium_plus_5: require('./assets/images/modell/premium_plus/premium_plus_5.jpg'),
    premium_plus_6: require('./assets/images/modell/premium_plus/premium_plus_6.jpg'),
    premium_plus_7: require('./assets/images/modell/premium_plus/premium_plus_7.jpg'),
    premium_plus_8: require('./assets/images/modell/premium_plus/premium_plus_8.jpg'),
    premium_plus_9: require('./assets/images/modell/premium_plus/premium_plus_9.jpg'),

    //lieferung_und_entladung
    stapler: require('./assets/images/lieferung_und_entladung/stapler.jpg'),
    kran: require('./assets/images/lieferung_und_entladung/kran.jpg'),
    selbstabholung: require('./assets/images/lieferung_und_entladung/selbstabholung.jpg'),

    //aufbau 
    montage: require('./assets/images/aufbau/montage.jpg'),
    selbstmontage: require('./assets/images/aufbau/selbstmontage.jpg'),

    //farbauftrag
    color: require('./assets/images/farbauftrag/color.jpg'),
    no_color: require('./assets/images/farbauftrag/no_color.jpg'),

    //vorbereitung
    hecke: require('./assets/images/vorbereitung/hecke.jpg'),
    zaun_alt: require('./assets/images/vorbereitung/zaun_alt.jpg'),
    fundament: require('./assets/images/vorbereitung/fundament.jpg'),

    //musterseite
    einseitig: require('./assets/images/musterseite/einseitig.jpg'),
    doppelseitig: require('./assets/images/musterseite/doppelseitig.jpg'),
}

export default IMAGES;