import React, { useEffect, useState, useRef } from 'react';
import './App.css';
import { createClient } from '@supabase/supabase-js';

import SelectBox from './components/SelectBox';
import Heading from './components/Heading';
import SubHeading from './components/SubHeading';
import Description from './components/Description';
import StandardPage from './screens/Standard';
import InputsPage from './screens/Inputs';
import jsonData from './configurator.json';
import { Button } from '@mantine/core';
import CategoriePage from './screens/Categories';
import Success from './screens/Success';
import toast, { Toaster } from 'react-hot-toast';
import { IoArrowForwardCircleOutline } from "react-icons/io5";
import { FaCircleArrowRight } from "react-icons/fa6";


const supabaseUrl = process.env.REACT_APP_SUPABASE_URL
const supabaseKey = process.env.REACT_APP_SUPABASE_KEY;
console.log(supabaseUrl);
const supabase = createClient(supabaseUrl, supabaseKey);

const App = () => {
  const [pageIndex, setPageIndex] = useState(0);

  const [firstPage, setFirstPage] = useState(true);
  const [lastPage, setLastPage] = useState(false);

  const [success, setSuccess] = useState(false);

  const standardPageRef = useRef(null);
  const inputsPageRef = useRef(null);
  const categoriesPageRef = useRef(null);

  const callGenerateOfferFunction = async () => {
    try {
      // Call the Edge function
        const { data, error } = await supabase.functions.invoke('generate_offer', {
          body: { 
            selectedOptions
          },
        })

      if (error) {
        console.error('Error calling Edge function:', error.message);
      } else {
        console.log('Result of Edge function:', data);
        // Do something with the result if needed
      }
    } catch (error) {
      console.error('Error calling Edge function 123:', error.message);
    }
  };

  const send = async () => {
    let isValid = false;
    const currentPageData = jsonData[pageIndex];

    // Call appropriate validation function based on the template of the current page
    switch (currentPageData.template) {
      case 'standard':
        try {
          isValid = await standardPageRef.current.validate();
        } catch (error) {
          showToastErrorMessage(error);
          return;
        }
        break;
      case 'inputs':
        try {
          isValid = await inputsPageRef.current.validate();
        } catch (error) {
          showToastErrorMessage(error);
          return;
        }
        break;
      case 'categories':
        try {
          isValid = await categoriesPageRef.current.validate();
        } catch (error) {
          showToastErrorMessage(error);
          return;
        }
        break;
      default:
        isValid = true; // If no template specified or template not recognized, proceed
        break;
    }

    if (isValid) {
      console.log('send', selectedOptions); 
      callGenerateOfferFunction(selectedOptions);
      setSuccess(true);
    } else {
      showToastErrorMessage('Eingabe fehlt');
      console.log('Validation failed'); // Log validation failure
    }
  }

  const [selectedOptions, setSelectedOptions] = useState({
    "size": {
      "length": null,
      "height": null,
    },
    "modell": null,
    "name": null,
    "musterseite": null,
    "lieferung_und_entladung": null,
    "aufbau": null,
    "farbauftrag": null,
    "vorbereitung": null,
    "kontakt": {
      "anrede": null,
      "name": null,
      "email": null,
      "telefonnummer": null,
      "address": null,
      "plz": null,
      "message": null,
      "livingplace": null,
      "layoutUrl": null,
    },
  });

  const goToPage = (index) => {
    setPageIndex(index);
  };

  const goToLastPage = () => {
    if (pageIndex > 0) {
      toast.remove();
      setPageIndex(pageIndex - 1);
    }
    scrollToId('gennow');
  }

  const goToNextPage = async (noCheck) => {
    if (noCheck) {
      if (pageIndex < jsonData.length - 1) {
        toast.remove();
        setPageIndex(pageIndex + 1);
      }
      scrollToId('gennow');
      return
    }
    console.log('111goToNextPage')
    let isValid = false;
    const currentPageData = jsonData[pageIndex];

    // Call appropriate validation function based on the template of the current page
    switch (currentPageData.template) {
      case 'standard':
        try {
          isValid = await standardPageRef.current.validate();
        } catch (error) {
          showToastErrorMessage(error);
          return;
        }
        break;
      case 'inputs':
        try {
          isValid = await inputsPageRef.current.validate();
        } catch (error) {
          showToastErrorMessage(error);
          return;
        }
        break;
      case 'categories':
        try {
          isValid = await categoriesPageRef.current.validate();
        } catch (error) {
          showToastErrorMessage(error);
          return;
        }
        break;
      default:
        isValid = true; // If no template specified or template not recognized, proceed
        break;
    }

    // Proceed to the next page only if validation passes
    if (isValid) {
      if (pageIndex < jsonData.length - 1) {
        toast.remove();
        setPageIndex(pageIndex + 1);
      }
    } else {
      showToastErrorMessage('Auswahl fehlt');
    }
    scrollToId('gennow');
  };

  const updateSelectedOption = (pageValue, optionValue) => {
    console.log('pageValue', pageValue)
    console.log('pageValue', optionValue)
    const updatedOptions = {...selectedOptions};
    updatedOptions[pageValue] = optionValue;
    setSelectedOptions(updatedOptions);
    if (pageValue !== 'size' && pageValue !== 'kontakt') {
      goToNextPage(true);
    
    }
    // console.log('updatedOptions', updatedOptions);
  };

  function scrollToId(id) {
    var element = document.getElementById(id);
    if (element) {
        element.scrollIntoView({ behavior: 'auto', block: 'start' });
    }
}

  useEffect(() => {
    if(pageIndex === 0) {
      setFirstPage(true);
    } else {
      setFirstPage(false);
    }
    if(pageIndex === jsonData.length - 1) {
      setLastPage(true);
    } else {
      setLastPage(false);
    }
  }, [pageIndex]);

  const showToastErrorMessage = (text) => {
    // toast.error(text, {
    //   position: "top-center",
    //   hideProgressBar: true,
    //   theme: "light",
    //   autoClose: 5000,
    // });
    // toast.remove();
    // toast.error(text);
    toast.dismiss();
    toast.error(text)
  };
  // const notify = () => toast('Here is your toast.');

  console.log('selectedOptions', selectedOptions)
  return (
    <div className='page'>
      {/* <ToastContainer/> */}
      {!success &&
        <>
          <Page 
            data={jsonData[pageIndex]} 
            selectedOption={selectedOptions[jsonData[pageIndex].value]} 
            updateSelectedOption={(optionValue) => updateSelectedOption(jsonData[pageIndex].value, optionValue)} 
            standardPageRef={standardPageRef}
            inputsPageRef={inputsPageRef}
            categoriesPageRef={categoriesPageRef}
            goToNextPage={() => goToNextPage()}
          />         
          <Toaster />
          <div className='page-navigation'>
            {lastPage && <Button variant="filled" onClick={() => send()}>Senden</Button>}
            {!lastPage && 
              <div style={{backgroundColor: "white", borderRadius: "50%", width: 55, height: 55, cursor: "pointer"}}><FaCircleArrowRight size={55} onClick={() => goToNextPage()} color="#009DE0"/></div>
            }
            {!firstPage && 
              <div style={{backgroundColor: "white", borderRadius: "50%", width: 55, height: 55, cursor: "pointer"}}><FaCircleArrowRight size={55} style={{transform: "rotate(180deg)"}} onClick={() => goToLastPage()} color="#009DE0"/></div>
            }
          </div> 
          <div style={{ display:"flex", marginBottom:"70px"}}></div>
        </>   
      }
      {success &&
        <Success value={selectedOptions} heading="Vielen Dank" description="Ihre Anfrage wurde erfolgreich versendet." />
      }
    </div>
  );
};

const Page = ({ data, selectedOption, updateSelectedOption, standardPageRef, inputsPageRef, categoriesPageRef, goToNextPage }) => {
  const { title, description, template, options, value, selectionRequired} = data;
    console.log("goToNextPage", goToNextPage)
    console.log("updateSelectedOption", updateSelectedOption)
  switch (template) {
    case 'standard':
      return <StandardPage ref={standardPageRef} selectionRequired={selectionRequired ? selectionRequired : null} value={value} heading={title} description={description} options={options} selectedOption={selectedOption} updateSelectedOption={updateSelectedOption} />;
    case 'inputs':
      return <InputsPage ref={inputsPageRef} selectionRequired={selectionRequired ? selectionRequired : null} value={value} heading={title} description={description} options={options} selectedOption={selectedOption} updateSelectedOption={updateSelectedOption}/>;
    case 'categories':
      return <CategoriePage ref={categoriesPageRef} goToNextPage={goToNextPage} selectionRequired={selectionRequired ? selectionRequired : null} value={value} heading={title} description={description} options={options} selectedOption={selectedOption} updateSelectedOption={updateSelectedOption}/>;
    default:
      return null;
  }
};

export default App;
